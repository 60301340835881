import '../assets/experience.css';
import Footer from './Footer';
import NavBar from './NavBar';

function Experience() {
  return (
    <div className="experience">
      <NavBar />
        <div className='experience-content-wrapper'>
            <div className='title'>
                <h1>Experience</h1>
            </div>

        </div>

      <Footer />
    </div>
  );
}

export default Experience;
